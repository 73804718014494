import Box from "@mui/material/Box";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Toolbar from "@mui/material/Toolbar";
import { useLocation, useNavigate } from "react-router-dom";
import { sidebarWidth } from "../../Utils/spacings";
import { SGELogo } from "../../Utils/Images";
import { allRoutes } from "../../Routes/AllRoutes";
import StoreOutlinedIcon from "@mui/icons-material/StoreOutlined";
import {
	EngineeringOutlined,
	HomeOutlined,
	ManageAccountsOutlined,
	PersonSearchOutlined,
	RecordVoiceOverOutlined,
	SettingsOutlined,
	SupervisorAccountOutlined,
	SupportAgentOutlined,
} from "@mui/icons-material";
import { Tooltip, Typography } from "@mui/material";
import { StyledDrawer, StyledDrawerBox, StyledDrawerList, StyledDrawerListItem } from "./pageLayoutStyles";
import { isAdminLoggedIn, isManagerLoggedIn, isOfficeManagerLoggedIn } from "../../Services/userService";
import { selectLogoUrl } from "../../Redux/Slices/generalSlice";
import { useSelector } from "react-redux";
import { selectUser } from "../../Redux/Slices/userSlice";
import { selectAllTextSnippets } from "../../Redux/Slices/textSnippetsSlice";

const LayoutSidebar = ({ open, handleDrawerToggle }: { open?: boolean; handleDrawerToggle?: () => void }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const user = useSelector(selectUser);
	const logoUrl: string = useSelector(selectLogoUrl);
	const textSnippets = useSelector(selectAllTextSnippets);

	const isAdmin = isAdminLoggedIn();
	const isOfficeManager = isOfficeManagerLoggedIn();
	const isManager = isManagerLoggedIn();

	const menuItems = [
		{
			icon: <HomeOutlined />,
			text: textSnippets["dashboard_installer_dashboard"] || "Dashboard",
			path: allRoutes.DASHBOARD,
		},
		...(isAdmin
			? [
					{
						icon: <RecordVoiceOverOutlined />,
						text: textSnippets["installer_admins_installer_dashboard"] || "Installer Admins",
						path: allRoutes.INSTALLER_ADMINS,
					},
					{
						icon: <ManageAccountsOutlined />,
						text: textSnippets["office_manager_installer_dashboard"] || "Office Managers",
						path: allRoutes.OFFICE_MANAGERS,
					},
			  ]
			: []),
		...(isAdmin || isOfficeManager
			? [
					{
						icon: <SupportAgentOutlined />,
						text: textSnippets["managers_installer_dashboard"] || "Managers",
						path: allRoutes.MANAGERS,
					},
					{
						icon: <EngineeringOutlined />,
						text: textSnippets["technicians_installer_dashboard"] || "Installation Crew",
						path: allRoutes.TECHNICIANS,
					},
			  ]
			: []),
		...(isAdmin || isOfficeManager || isManager
			? [
					{
						icon: <SupervisorAccountOutlined />,
						text: textSnippets["representatives_installer_dashboard"] || "Representatives",
						path: allRoutes.REPRESENTATIVES,
					},
			  ]
			: []),
		// ...(!isTechnician ? [ { icon: <PersonSearchOutlined />, text: "Customers", path: allRoutes.CUSTOMERS }]: [] ),
		{
			icon: <PersonSearchOutlined />,
			text: textSnippets["customers_installer_dashboard"] || "Customers",
			path: allRoutes.CUSTOMERS,
		},
		{
			icon: <SettingsOutlined />,
			text: textSnippets["account_settings_installer_dashboard"] || "Account Settings",
			path: allRoutes.ACCOUNT_SETTINGS,
		},
		...(isAdmin
			? [{ icon: <StoreOutlinedIcon />, text: "Company Information", path: allRoutes.COMPANY_INFORMATION }]
			: []),
	];

	const handleClickItem = (path: string) => {
		if (open) handleDrawerToggle?.();
		navigate(path);
	};

	const DrawerBlock = (
		<StyledDrawerBox>
			<Toolbar sx={{ justifyContent: "center", paddingInline: "12px !important" }}>
				{user.company?.ImageUrl ? (
					<img
						alt="Logo"
						src={user.company?.ImageUrl || logoUrl || SGELogo}
						style={{ cursor: "pointer" }}
						onClick={() => handleClickItem(allRoutes.DASHBOARD)}
						className="main-logo"
					/>
				) : (
					<Tooltip title={user.company?.name || "Installer Company"} arrow placement="bottom">
						<Typography
							fontSize={17}
							onClick={() => handleClickItem(allRoutes.DASHBOARD)}
							color="primary"
							fontWeight={600}
							sx={{
								cursor: "pointer",
								textAlign: "center",
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
								maxWidth: "100%", // Adjust based on your layout needs
							}}
						>
							{user.company?.name || "Installer Company"}
						</Typography>
					</Tooltip>
				)}
			</Toolbar>

			<StyledDrawerList>
				{Object.values(menuItems).map((item) => {
					const isActive = item.path === location.pathname || location.pathname?.includes(item.path);

					return (
						<StyledDrawerListItem
							disablePadding
							key={item.text}
							isActive={isActive}
							onClick={() => handleClickItem(item.path)}
						>
							<ListItemButton>
								<ListItemIcon>{item.icon}</ListItemIcon>
								<Typography sx={{ py: 10, color: "inherit", fontWeight: 500 }}>{item.text}</Typography>
							</ListItemButton>
						</StyledDrawerListItem>
					);
				})}
			</StyledDrawerList>
		</StyledDrawerBox>
	);

	return (
		<Box component="nav" sx={{ width: { sm: sidebarWidth }, flexShrink: { sm: 0 } }} aria-label="mailbox folders">
			<StyledDrawer
				open={open}
				variant="temporary"
				onClose={handleDrawerToggle}
				ModalProps={{ keepMounted: true, disableScrollLock: true }} // Better open performance on mobile.
				sx={{ display: { xs: "block", sm: "none" } }}
			>
				{DrawerBlock}
			</StyledDrawer>
			<StyledDrawer open variant="permanent" sx={{ display: { xs: "none", sm: "block" } }}>
				{DrawerBlock}
			</StyledDrawer>
		</Box>
	);
};

export default LayoutSidebar;
