import { Box, Typography } from "@mui/material";
import CustomDialog from "../Common/CustomDialog";
import { PersonRemoveOutlined } from "@mui/icons-material";
import CustomButton from "../Common/CustomButton";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { IconSquareBox } from "../Login/loginStyles";
import colors from "../../Utils/colors";
import CustomDropdown from "../Common/CustomDropdown";
import {
	assignRepresentative,
	changeManager,
	changeOfficeManager,
	getAllManagers,
	getAllOfficeManagers,
	getAllRepresentatives,
} from "../../Services/installersService";

interface ForgotPasswordDialogProps {
	open: boolean;
	onClose?: () => void;
	activeUser: { text: string; id: string } | null;
	currentSelection?: { _id: string; name: string } | null;
	onSuccess?: () => void;
	type?: "Office Manager" | "Manager";
}

const AssignRepresentativeDialog = ({
	open,
	onClose,
	activeUser,
	currentSelection,
	onSuccess: onSuccess,
	type,
}: ForgotPasswordDialogProps) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [selectedId, setSelectedId] = useState<string | undefined>("");
	const [allTechnicians, setAllTechnicians] = useState<Array<any>>([]);

	useEffect(() => {
		if (open) {
			getAllUsers();
		}
	}, [open]);

	const getAllUsers = async () => {
		setLoading(true);
		try {
			let { data }: any =
				type === "Office Manager"
					? await getAllOfficeManagers()
					: type === "Manager"
					? await getAllManagers()
					: await getAllRepresentatives();
			data = data.map((item: any) => ({ ...item, value: item._id, text: item.name }));
			setAllTechnicians(data);

			if (currentSelection) {
				setSelectedId(currentSelection?._id);
			} else {
				setSelectedId("");
			}
		} catch (error: any) {
			toast.error(error);
		}
		setLoading(false);
	};

	const handleAssign = async () => {
		setLoading(true);
		try {
			if (type === "Office Manager") {
				const payload = {
					userId: activeUser?.id,
					officeManagerId: selectedId,
				};
				await changeOfficeManager(activeUser?.id || "", payload);
			} else if (type === "Manager") {
				const payload = {
					userId: activeUser?.id,
					managerId: selectedId,
				};
				await changeManager(activeUser?.id || "", payload);
			} else {
				const payload = {
					userId: activeUser?.id,
					installerId: selectedId,
				};
				await assignRepresentative(activeUser?.id || "", payload);
			}
			await onSuccess?.();

			onClose?.();
			toast.success(`${type || "Representative"} assigned successfully!`);
		} catch (error: any) {
			toast.error(error);
		}
		setLoading(false);
	};

	const handleDropdown = (value: string) => {
		setSelectedId(value);
	};

	return (
		<CustomDialog open={open} onClose={onClose}>
			<IconSquareBox>
				<PersonRemoveOutlined />
			</IconSquareBox>

			<Typography variant="h2" my={16} textAlign="center">
				Assign {type || "Representative"}
			</Typography>
			<Typography fontSize={16} mb={16} textAlign="center" color={colors.textMid}>
				Please select a{type === "Office Manager" ? "n" : ""} {type?.toLowerCase() || "representative"} to assign to{" "}
				{activeUser?.text}?
			</Typography>

			<CustomDropdown options={allTechnicians} value={selectedId} onChange={handleDropdown} minWidth="100%" />

			<Box display="grid" gridTemplateColumns="1fr 1fr" gap={10} mt={32}>
				<CustomButton variant="outlined" fullWidth disabled={loading} onClick={onClose}>
					Cancel
				</CustomButton>
				<CustomButton
					fullWidth
					disabled={!selectedId || loading}
					// disabled={currentSelection === current?._id || loading}
					onClick={handleAssign}
				>
					Assign
				</CustomButton>
			</Box>
		</CustomDialog>
	);
};

export default AssignRepresentativeDialog;
