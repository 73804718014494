import { Box, Typography } from "@mui/material";
import CustomDialog from "../Common/CustomDialog";
import { PersonRemoveOutlined } from "@mui/icons-material";
import CustomButton from "../Common/CustomButton";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { IconSquareBox } from "../Login/loginStyles";
import colors from "../../Utils/colors";
import CustomDropdown from "../Common/CustomDropdown";
import {roles} from '../../Utils/tokenKeyValue';
import {
    assignInstallerToCustomer,
	assignRepresentative,
	changeManager,
	changeOfficeManager,
	getAllManagers,
	getAllOfficeManagers,
	getAllRepresentatives,
	getManagersOfOfficeManager,
	getRepresentativesOfManager,
} from "../../Services/installersService";

interface PROPS {
	open: boolean;
	onClose?: () => void;
	selectedCustomer : {  _id: string  , name : string , showWarning : boolean }  | null;
	currentSelection?: { _id: string; name: string , role : string,officeManagerId? : string,
		managerId? : string  };
	onSuccess?: () => void;
	type?: "office-manager" |"manager" | "representative";
	
}

const AssignInstallerToCustomerDialog = ({
	open,
	onClose,
	selectedCustomer,
	currentSelection,
	onSuccess: onSuccess,
	type,

}: PROPS) => { 
    const typeText =type === roles.OFFICE_MANAGER ? "Office Manager" :type === roles.MANAGER ? "Manager" : "Representative";
	const [loading, setLoading] = useState<boolean>(false);
	const [selectedId, setSelectedId] = useState<string | undefined>("");
	const [allInstallers, setAllInstallers] = useState<Array<any>>([]);

	useEffect(() => {
		if (open) {
			getAllUsers();
		}
	}, [open]);

	const getAllUsers = async () => {
		if(currentSelection){

			setLoading(true);
			try {
				let { data }: any =
					type === roles.OFFICE_MANAGER
						? await getAllOfficeManagers()
						: type === roles.MANAGER
						? await getManagersOfOfficeManager((currentSelection.officeManagerId || "")?.toString())
						: await getRepresentativesOfManager((currentSelection.managerId || "")?.toString());
				data = data.map((item: any) => ({ ...item, value: item._id, text: item.name }));
				setAllInstallers(data);
	
				if (currentSelection) {
					setSelectedId(currentSelection?._id);
				} else {
					setSelectedId("");
				}
			} catch (error: any) {
				toast.error(error);
			}
			setLoading(false);
		}
		
		
	};

	const handleAssign = async () => {
        if(selectedCustomer){
            setLoading(true);
            try {
                const payload = {
                    role : currentSelection?.role  ,
                    customerId : selectedCustomer._id ,
                    selectedInstallerId : selectedId,
    
                    
                };

                console.log({payload})
                const res  =  await assignInstallerToCustomer(payload)
                console.log({res});
                await onSuccess?.();
    
                onClose?.();
                toast.success(`${type || "Representative"} assigned successfully!`);
            } catch (error: any) {
                toast.error(error);
            }
            setLoading(false);
        }
		
	};

	const handleDropdown = (value: string) => {
		setSelectedId(value);
	};
    console.log({currentSelection , selectedCustomer})

	return (
		<CustomDialog open={open} onClose={onClose}>
			<IconSquareBox>
				<PersonRemoveOutlined />
			</IconSquareBox>

			<Typography variant="h2" my={16} textAlign="center">
				Assign { typeText } 
			</Typography>
			<Typography fontSize={16} mb={16} textAlign="center" color={colors.textMid}>
				Please select a {typeText } to assign to{" "}
				{selectedCustomer?.name}?
			</Typography>

			<CustomDropdown options={allInstallers} value={selectedId} onChange={handleDropdown} minWidth="100%" />
            {
                selectedCustomer && selectedCustomer.showWarning && <Typography fontSize={12} mt={10}><b>Warning:</b>  Reassiging {typeText} will remove the {type === roles.OFFICE_MANAGER ? "Manager and Representative" :type === roles.MANAGER ? "Representative" : ""} assigned to this customer. </Typography>
                
            }

			<Box display="grid" gridTemplateColumns="1fr 1fr" gap={10} mt={32}>
				<CustomButton variant="outlined" fullWidth disabled={loading} onClick={onClose}>
					Cancel
				</CustomButton>
				<CustomButton
					fullWidth
					disabled={!selectedId || loading}
					onClick={handleAssign}
				>
					Assign
				</CustomButton>
			</Box>
		</CustomDialog>
	);
};

export default AssignInstallerToCustomerDialog;
