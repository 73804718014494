import { useNavigate, useParams } from "react-router-dom";
import PageLayout from "../PageLayout/PageLayout";
import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { allRoutes } from "../../Routes/AllRoutes";
import DeleteDialog from "../Common/DeleteDialog";
import ProfileHeader from "../Common/ProfileHeader";
import { toast } from "react-toastify";
import {
	deleteInstallerById,
	getDetailsOfInstaller,
	getManagersOfOfficeManager,
} from "../../Services/installersService";
import colors from "../../Utils/colors";
import { borderRadius } from "../../Utils/spacings";

const InstallerAdminDetails = () => {
	const { id } = useParams();
	const navigate = useNavigate();

	const [data, setData] = useState<any>({});
	const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
	const [projects, setProjects] = useState<Array<any>>([]);
	const [loading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		window.scrollTo(0, 0);
		getDetails();
	}, []);

	const getDetails = async () => {
		if (!id) navigate(allRoutes.DASHBOARD);

		setLoading(true);
		try {
			const { data: userData } = await getDetailsOfInstaller((id || "")?.toString());
			setData(userData);

			// const { data: assignedCustomers } = await getManagersOfOfficeManager((id || "")?.toString());
			const { data: assignedCustomers } = await getManagersOfOfficeManager((userData._id || "")?.toString());

			setProjects(assignedCustomers || []);
		} catch (error: any) {
			toast.error(error);
		}
		setLoading(false);
	};

	const openDialog = () => setOpenDeleteDialog(true);
	const closeDialog = () => setOpenDeleteDialog(false);

	const handleEdit = () => { 
		navigate(allRoutes.EDIT_INSTALLER_ADMIN.replace(":id", (id || "")?.toString()));
	};

	const handleDelete = async () => {
		try {
			const { data: res } = await deleteInstallerById(data._id || "");
			if (res === "Account deleted") {
				toast.success("Installer admin deleted successfully!");
				navigate(allRoutes.DASHBOARD);
			}
		} catch (error: any) {
			toast.error(error);
		}
	};

	// 653f85137a9990d1f664d434

	const fields = [
		{ text: "Name", key: "name" },
		{ text: "Email address", key: "email" },
		{ text: "Address", key: "address" },
		{ text: "Phone Number", key: "phone_no" },
		{ text: "Email Status", key: "isEmailVerified" , customComponent : ()=>(data.isEmailVerified ? "Verified" :  "Not Verified")  },
	];

	return (
		<PageLayout loading={loading} backButtonPath={allRoutes.DASHBOARD}>
			<ProfileHeader
				data={data}
				userType="Installer Admin"
				handleEdit={handleEdit}
				handleDelete={openDialog}
				disableDeleteButton={!!projects?.length}
			/>

			<Box
				display="grid"
				gridTemplateColumns={{ xs: "1fr", md: "340px 1fr" }}
				gap={{ xs: 10, md: 32 }}
				alignItems="center"
				mt={45}
			>
				{fields?.map((field: any) => (
					<React.Fragment key={field.key}>
						<Typography variant="h6">{field.text}</Typography>
						{field.customComponent ? (
							field.customComponent({ text: data?.[field.key] })
						) : field.subValues ? (
							<Box
								display="grid"
								alignItems="flex-start"
								gridTemplateColumns={{ xs: "1fr 1fr", md: "1fr 1fr 1fr 1fr" }}
								gap={{ xs: 10, md: 32 }}
								sx={{
									padding: 16,
									bgcolor: colors.primary + "09",
									border: `1.5px dotted ${colors.primary}`,
									borderRadius: borderRadius.md, // TODO for Waleed: create a common component for this purpleBox and use it in all places e.g, AccountSettings, ConfigurationsPage, EditCustomer, CustomerDetails
									minWidth: { sm: "auto", xs: "100%" },
								}}
							>
								{field.subValues?.map((subField: any) => (
									<Box key={subField.label}>
										<Typography fontSize={12} fontWeight={600} mb={3}>
											{subField.label}
										</Typography>
										<Typography>{subField.value}</Typography>
									</Box>
								))}
							</Box>
						) : (
							<Typography>{data?.[field.key] || field.errorMessage || "Not given"}</Typography>
						)}
					</React.Fragment>
				))}
			</Box>

			<DeleteDialog
				open={openDeleteDialog}
				onClose={closeDialog}
				userType="Office Manager"
				user={data}
				onDelete={handleDelete}
			/>
		</PageLayout>
	);
};

export default InstallerAdminDetails;
