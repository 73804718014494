const colors = {
	// primary: "#7D26CD",
	// primaryExtraLight: "#F9F8FE",
	// primaryLightNew: "#F3E6FF",
	primary: "#FFA800",
	primaryLight: "#FFA80080",
	primaryMidLight: "#FFDB96",
	primaryExtraLight: "#FFF6E6",
	primaryLightNew: "#FFF6E6",
	text: "#1C1D34",
	textMid: "#475467",
	textDarkBlue: "#090F4E",
	border: "#E6E6E6",
	lightGray: "#F6F5F8",
	lightGray2: "#F2F1F4",
	gray: "#7A7A7A",
	link: "#3581DB",
	orange: "#ED6B42",
	success: "#377E36",
	error: "#B12F30",
	successLight: "#19B616",
	errorLight: "#EC3F36",
	successBg: "#E0F0E4",
	errorBg: "#FEECEB",
	darkBg: "#2A223C",
};

export default colors;
