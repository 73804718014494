import RouteNavigation from "./Routes/RouteNavigation";
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "@mui/material";
import theme from "./theme";
import { Provider } from "react-redux";
import { store } from "./Redux/store";
import { GoogleOAuthProvider } from "@react-oauth/google";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import "./ToastStyles.css";
import "chart.js/auto";

const App = () => {
	const googleClientId = process.env.REACT_APP_GOOGLE_OAUTH_ID ?? "";

	return (
		<ThemeProvider theme={theme}>
			<GoogleOAuthProvider clientId={googleClientId}>
				<Provider store={store}>
					<RouteNavigation />
					<ToastContainer hideProgressBar autoClose={2500} />
				</Provider>
			</GoogleOAuthProvider>
		</ThemeProvider>
	);
};

export default App;
