import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../store";
import http from "../../Services/httpService";

export interface GeneralState {
	logo: string;
}

const initialState: GeneralState = {
	logo: "",
};

export const fetchLogo = createAsyncThunk("general/fetchLogo", async (_, { rejectWithValue }) => {
	try {
		const response = await http.get("/parameters/get-logo");

		return response.data;
	} catch (error) {
		return rejectWithValue("Failed to fetch logo");
	}
});

export const generalSlice = createSlice({
	name: "generalSlice",
	initialState: initialState,
	reducers: {
		// Reducer logic here
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchLogo.pending, (state) => {
				// Optionally, handle loading state
			})
			.addCase(fetchLogo.fulfilled, (state, action) => {
				// Action payload is the fetched logo URL
				state.logo = action.payload;
			})
			.addCase(fetchLogo.rejected, (state, action) => {
				console.error(action.payload);
			});
	},
});

export const selectLogoUrl = (state: RootState) => state.general.logo;

export default generalSlice.reducer;
