import { Chip } from "@mui/material";
import { chipStatuses } from "../../Utils/enums";

interface StatusChipProps {
	status?: string;
}

const StatusChip = ({ status }: StatusChipProps) => {
	const currentStatus =
		Object.values(chipStatuses)?.find((item) => item.values?.includes(status || "")) || chipStatuses.IN_PROGRESS;

	return (
		<Chip
			label={status}
			icon={<currentStatus.icon />}
			sx={{
				backgroundColor: currentStatus.bgColor,
				color: currentStatus.color,
				"& svg": {
					path: {
						fill: currentStatus.color,
					},
				},
			}}
		/>
	);
};

export default StatusChip;
